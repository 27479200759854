import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from 'src/store/customFetchBase';
import {
  ApiParams,
  AuditObservationResponse,
  ChecklistObservationResponse,
  ControlObservationResponse,
  DraftReportObservationStatsResponse,
  EngagementResponse,
  FileResponse,
  ObservationCommentsResponse,
  ObservationComplianceResponse,
  ObservationSampleResponse,
} from '../types';
import { MarkFinalObservationInput } from 'src/views/pages/review/Observation/List';
import { NewIssueDraftInput } from 'src/views/pages/review/Draft/Issue';
import { NewDraftSummaryInput } from 'src/views/pages/review/Draft/Summary';
import { NewObservationReviewInput } from 'src/views/pages/compliance/Review';

export const reviewApi = createApi({
  reducerPath: 'reviewApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    sendObservationReview: builder.mutation<string, NewObservationReviewInput>({
      query(data: any) {
        return {
          url: `/auditFeedback/complianceReview/${data.complianceId}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updateExectiveSummary: builder.mutation<string, NewDraftSummaryInput>({
      query(data) {
        return {
          url: '/auditDraft/updateSummary',
          method: 'PUT',
          body: data,
        };
      },
    }),
    updateExectiveSummaryEx: builder.mutation<string, any[]>({
      query(data: any[]) {
        return {
          url: `/auditDraft/updateObvComments/${data[0].engagementId}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    issueDraftReport: builder.mutation<string, NewIssueDraftInput>({
      query(data) {
        return {
          url: '/auditDraft/issuedraft',
          method: 'PUT',
          body: data,
        };
      },
    }),
    markObservationFinal: builder.mutation<ChecklistObservationResponse, MarkFinalObservationInput>(
      {
        query(data) {
          return {
            url: `/auditReview/move`,
            method: 'POST',
            body: data,
          };
        },
      },
    ),
    getReviewObservations: builder.query<ChecklistObservationResponse[], string>({
      query(id) {
        return {
          url: `/auditReview/getObservations/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        checkListObservationResponseDTO: ChecklistObservationResponse[];
      }) => data.checkListObservationResponseDTO,
    }),
    getReviewEngagementsFinalize: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/auditReview/getEngagementsByUser',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getReviewEngagementsComments: builder.query<EngagementResponse[], number>({
      query(status) {
        return {
          url: `/auditFeedback/getEngagementsByStakeHolder/${status}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getDraftEngagements: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/auditDraft/getEngagementsByUser',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getObservationReviewSampling: builder.query<
      ObservationSampleResponse,
      { engid: string; obvid: string }
    >({
      query({ engid, obvid }) {
        return {
          url: `/auditFeedback/getSampling/${engid}/${obvid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationSampleResponseDTO: ObservationSampleResponse }) =>
        data.observationSampleResponseDTO,
    }),
    getObservationReviewComments: builder.query<
      ObservationCommentsResponse,
      { engid: string; obvid: string }
    >({
      query({ engid, obvid }) {
        return {
          url: `/auditFeedback/getComments/${engid}/${obvid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationCommentResponseDTO: ObservationCommentsResponse }) =>
        data.observationCommentResponseDTO,
    }),
    getObservationReviewCompliance: builder.query<
      ObservationComplianceResponse,
      { engid: string; obvid: string }
    >({
      query({ engid, obvid }) {
        return {
          url: `/auditFeedback/getCompliance/${engid}/${obvid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        observationComplianceResponseDTO: ObservationComplianceResponse;
      }) => data.observationComplianceResponseDTO,
    }),
    getEngagementSummary: builder.query<EngagementResponse, string>({
      query(id) {
        return {
          url: `/auditDraft/getEngSummary/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse }) =>
        data.auditEngagementResponseDTO,
    }),
    getObservationSummary: builder.query<AuditObservationResponse[], string>({
      query(id) {
        return {
          url: `/auditDraft/getObvSummary/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditObservationResponseDTO: AuditObservationResponse[] }) =>
        data.auditObservationResponseDTO,
    }),
    getDraftReportSummaryFx: builder.query<any, bigint>({
      query(id) {
        return {
          url: `/auditDraft/rptsummary/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { fixedEngDraftResDTO: any }) => data.fixedEngDraftResDTO,
    }),
    getDraftReportDetailsFx: builder.query<any[], string>({
      query(id) {
        return {
          url: `/auditDraft/rptdetails/${id}/1/999`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwFixedEngDraft: any[] }) => data.vwFixedEngDraft,
    }),
    getObservationStats: builder.query<DraftReportObservationStatsResponse, string>({
      query(id) {
        return {
          url: `/auditDraft/getObvSummaryByRisk/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        engagementDraftReportResponseDTO: DraftReportObservationStatsResponse;
      }) => data.engagementDraftReportResponseDTO,
    }),
    getDraftVersions: builder.query<FileResponse[], ApiParams>({
      query({ engid, attid }) {
        return {
          url: `/auditDraft/getDraftVersions/${engid}/${attid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { fileAttachmentResponseDTO: FileResponse[] }) =>
        data.fileAttachmentResponseDTO,
    }),
    getArchiveDraftVersions: builder.query<FileResponse[], ApiParams>({
      query({ engid, attid }) {
        return {
          url: `/archiveEng/getDraftVersions/${engid}/${attid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { fileAttachmentResponseDTO: FileResponse[] }) =>
        data.fileAttachmentResponseDTO,
    }),
    getEngagementAnnexers: builder.query<FileResponse[], { engid: number; type: number }>({
      query({ engid, type }) {
        return {
          url: `/auditReview/getAnnexures/${engid}/${type}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { fileAttachmentResponseDTO: FileResponse[] }) =>
        data.fileAttachmentResponseDTO,
    })
  }),
});

export const {
  useSendObservationReviewMutation,
  useUpdateExectiveSummaryMutation,
  useUpdateExectiveSummaryExMutation,
  useIssueDraftReportMutation,
  useMarkObservationFinalMutation,
  useGetReviewEngagementsFinalizeQuery,
  useLazyGetReviewEngagementsCommentsQuery,
  useGetDraftEngagementsQuery,
  useLazyGetReviewObservationsQuery,
  useLazyGetObservationReviewSamplingQuery,
  useLazyGetObservationReviewCommentsQuery,
  useLazyGetObservationReviewComplianceQuery,
  useLazyGetEngagementSummaryQuery,
  useLazyGetObservationSummaryQuery,
  useLazyGetObservationStatsQuery,
  useLazyGetDraftVersionsQuery,
  useLazyGetArchiveDraftVersionsQuery,
  useLazyGetDraftReportSummaryFxQuery,
  useLazyGetDraftReportDetailsFxQuery,
  useLazyGetEngagementAnnexersQuery
} = reviewApi;
