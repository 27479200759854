import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/store/types';
import PropTypes from 'prop-types';
import axiosInt from 'src/utils/axios';
import { setUser } from 'src/store/feature/authSlice';
import { store } from 'src/store/Store';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  error: any;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (UserName: string, Password: string, licenseKey: string) => Promise<void>;
  logout: () => void;
  register: (
    email: string,
    userName: string,
    firstName: string,
    lastName: string,
    password: string,
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type ErrorAction = {
  type: 'LOGIN_ERR';
  payload: {
    error: any;
  };
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction | ErrorAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: undefined,
};

const setSession = (user: User | null): void => {
  if (user) {
    localStorage.setItem('accessToken', user.authToken!);
    localStorage.setItem('user', JSON.stringify(user));
    axiosInt.defaults.headers.common.Authorization = `Bearer ${user.authToken!}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    delete axiosInt.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: any): AuthState => {
    const { isAuthenticated, user } = action.payload;

    store.dispatch(setUser(user));

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      error: null,
    };
  },
  LOGIN: (state: AuthState, action: any): AuthState => {
    const { user } = action.payload;

    store.dispatch(setUser(user));

    return {
      ...state,
      isAuthenticated: true,
      user,
      error: null,
    };
  },
  LOGIN_ERR: (state: AuthState, action: any): AuthState => {
    const { error } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user: null,
      error: error,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
    error: null,
  }),
  REGISTER: (state: AuthState, action: any): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      error: null,
    };
  },
};

export const JWTAuthReducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(JWTAuthReducer, initialAuthState);

  // const [isAuthenticated, setIsAuthenticated] = React.useState(
  //   (store.getState().authState as AuthState).isAuthenticated
  // );
  // const [user, setUser] = React.useState(
  //   (store.getState().authState as AuthState).user
  // );

  // useEffect(() => {
  //   // Listen for changes to the store and update the state
  //   store.subscribe(() => {
  //     setIsAuthenticated(
  //       (store.getState().authState as AuthState).isAuthenticated
  //     );
  //     setUser((store.getState().authState as AuthState).user);
  //   });
  // }, []);

  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    const user = JSON.parse(window.localStorage.getItem('user') || '{}');

    if (user && accessToken) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: user,
        },
      });
    } else {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  // useEffect(() => {
  //   const initialize = async (): Promise<void> => {
  //     const accessToken = window.localStorage.getItem('accessToken');

  //     if (accessToken /*&& verify(accessToken, JWT_SECRET)*/) {
  //       setSession(accessToken);

  //       await axiosInt
  //         .get<{ editProfileResponseDTO: User }>('/api/auth/profile')
  //         .then((response) => {
  //           const { editProfileResponseDTO } = response.data;

  //           dispatch({
  //             type: 'INITIALIZE',
  //             payload: {
  //               isAuthenticated: true,
  //               user: editProfileResponseDTO
  //             }
  //           });
  //         })
  //         .catch((error) => {
  //           if ([401, 403].includes(error.response.status)) {
  //             dispatch({
  //               type: 'INITIALIZE',
  //               payload: {
  //                 isAuthenticated: false,
  //                 user: null
  //               }
  //             });
  //             window.location.href = '/dashboards/index';
  //           }
  //         });
  //     } else {
  //       dispatch({
  //         type: 'INITIALIZE',
  //         payload: {
  //           isAuthenticated: false,
  //           user: null
  //         }
  //       });
  //     }
  //   };

  //   initialize();
  // }, []);

  const login = async (userName: string, password: string, licenseKey: string): Promise<void> => {
    await axiosInt
      .post<{ accessToken: string; user: User }>('/api/auth/login', {
        userName,
        password,
        licenseKey: process.env.REACT_APP_LICENSE_KEY
      })
      .then((response) => {
        if (response) {
          // let { accessToken, user } = response.data as any;
          if ((response.data as any).loginResponseDTO) {
            const accessToken = (response.data as any).loginResponseDTO.authToken;
            const user = (response.data as any).loginResponseDTO;

            if (user && accessToken) {
              setSession(user);

              dispatch({
                type: 'LOGIN',
                payload: {
                  user,
                },
              });
            }
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: 'LOGIN_ERR', payload: { error } });
      });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (formvalues: any): Promise<void> => {
    const newuser: User = JSON.parse(JSON.stringify(formvalues));

    newuser.auditAreaTypeMasterId = formvalues.location.id;
    newuser.designationId = formvalues.designation.id;
    newuser.managerId = formvalues.manager.id;
    newuser.primaryRoleId = formvalues.primaryRole.id;
    newuser.image = 'src';

    // const response = await axiosInt.post<{ accessToken: string; user: User }>(
    //   '/api/user/create',
    //   newuser,
    // );

    // const { accessToken, user } = response.data;
    //
    // window.localStorage.setItem("accessToken", accessToken);

    // dispatch({
    //   type: "REGISTER",
    //   payload: {
    //     user,
    //   },
    // });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
