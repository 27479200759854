export const INDEX = '/';
export const LOGIN = '/login';
export const BLANK = '/dashboards/blank';
export const CLIENT = '/dashboards/client';
export const OBSERVATION_SETUP = '/workprogram/observation/draft';
export const CATEGORY_WORKPROGRAM_SELECTION = '/workprogram/observation/checklist';
export const TO_WORKPROGRAM_OBSER_LIST = '/workprogram/observation/list';
export const WORKPROGRAM_CREATE_CONTROLS = '/workprogram/controls/create';
export const TO_REVIEW_ENG_FINAL = '/review/engagement/finalize';
export const TO_REVIEW_ENG_COMMENTS = '/review/engagement/comments';
export const TO_REVIEW_ENG_COMPLIANCE = '/compliance/engagement';
export const TO_REVIEW_ENG_POSTCOMPLIANCE = '/compliance/postreview';
export const TO_REVIEW_OBV_FINAL = '/review/observation/list';
export const TO_SYSTEM_REMINDERS = '/review/reminders';
export const TO_REVIEW_OBV_COMPLIANCE = '/compliance/observation/list';
export const TO_NEW_CONTROL_OBSERVATION = '/workprogram/observation/draft';
export const TO_NEW_CONTROL_OBSERVATION_FEEBACK = '/compliance/feedback/control';
export const DOWNLOAD_IMAGE = '/file/download?fileName=';
export const TO_COMPLIANCE_COMMENTS = '/compliance/observation/comments';
export const TO_DRAFT_GENERATE = '/review/draft/generate';
export const TO_DRAFT_GENERATE_FX = '/review/draft/generatefx';
export const TO_AUDIT_RATING_VIEW = '/review/draft/rating';
export const TO_SETUP_USERS = '/setup/users';
export const TO_SETUP_ROLES = '/setup/roles';
export const TO_SETUP_PERM = '/setup/perms';
export const TO_SETUP_ORGANUNIT = '/setup/organunit';
export const TO_SETUP_AREAS = '/setup/universe';
export const TO_SETUP_CATEGORIES = '/setup/control/category';
export const TO_SETUP_SOURCES = '/setup/control/source';
export const TO_SETUP_LIBRARY = '/setup/controls';
export const TO_SETUP_OBSERVATIONS = '/setup/control/observation';
export const TO_SETUP_CHECKLIST = '/setup/control/checklist';
export const TO_SETUP_ENGAGEMENT = '/planning/engagement';
export const TO_PLANNING_ENGAGEMENT_LIST = '/planning/engagement/list';
export const TO_PLANNING_ENGAGEMENT_TIMELINE = '/planning/engagement/timeline';
export const TO_MANAGE_WORKPROGRAMS = '/workprogram/engagement/manage';
export const TO_WP_ADD_CONTROLS = '/workprogram/controls/create';
export const TO_WP_EXECUTE_CONTROLS = '/workprogram/controls/execute';
export const TO_WP_REVIEW_CONTROLS = '/workprogram/controls/review';
export const TO_AUDIT_PERFORMANCE_REPORT = '/reports/auditperm';
export const TO_COMPLIANCE_SUMMARY_REPORT = '/reports/compsumm';
export const TO_ENGAGEMENT_SUMMARY_REPORT = '/reports/engsumm/1';
export const TO_PLAN_AUDIT_PLAN_REPORT = '/reports/auditdays';
export const TO_SETUP_RISK_FACTOR = '/setup/risk/factor';
export const TO_SETUP_AUDIT_UNIVERSE = '/setup/universe';
export const TO_SETUP_ORGANIZATION_HIERARCHY = '/setup/organunit';
export const TO_SETUP_CONTROL_CATEGORY = '/setup/control/category';
export const TO_SETUP_CONTROL_SOURCE = '/setup/control/source';
export const TO_REVIEW_REMINDERS = '/setup/review/reminders';
export const TO_DYNAMIC_CRUDS = '/setup/general';
export const TO_SETUP_CRITERIA = '/setup/risk/criteria/view';
export const TO_SETUP_AUDIT_AREA= '/setup/risk/assessment/list';
