import {
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { AppState } from './Store';
/*import { Mutex } from 'async-mutex';*/

const baseUrl = process.env.REACT_APP_REMOTE_ENDPOINT;

// Create a new mutex
/*const mutex = new Mutex();*/

const customFetchBase = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = ((getState() as AppState).authState as any).user.authToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

/* const customFetchBase: BaseQueryFn<
//   string | FetchArgs,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   // wait until the mutex is available without locking it
//   await mutex.waitForUnlock();
//   let result = await baseQuery(args, api, extraOptions);
//   console.log(result);
//   if ((result.error?.data as any)?.message === 'You are not logged in') {
//     if (!mutex.isLocked()) {
//       const release = await mutex.acquire();

//       try {
//         const refreshResult = await baseQuery(
//           { credentials: 'include', url: 'auth/refresh' },
//           api,
//           extraOptions
//         );

//         if (refreshResult.data) {
//           // Retry the initial query
//           result = await baseQuery(args, api, extraOptions);
//         } else {
//           api.dispatch(logout());
//           window.location.href = '/login';
//         }
//       } finally {
//         // release must be called once the mutex should be released again.
//         release();
//       }
//     } else {
//       // wait until the mutex is available without locking it
//       await mutex.waitForUnlock();
//       result = await baseQuery(args, api, extraOptions);
//       console.log(result);
//     }
//   }

//   return result;
// }; */

export default customFetchBase;
