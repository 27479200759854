import React from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Grid, Card, Box, Typography, useTheme, Avatar } from '@mui/material';
import { alpha } from '@mui/system';
import { setVisibilityFilter } from 'src/store/apps/lists/ListSlice';
import { ListTopCardsType } from 'src/store/types';
import { AddAlertTwoTone as AddAlertIcon } from '@mui/icons-material';
import { useSelector } from 'src/store/Store';

type ListFilterProps = {
  boxes: ListTopCardsType[];
};

const ListFilter = ({ boxes }: ListFilterProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // Function for handling card click
  const boxClicked = (box: ListTopCardsType) => {
    if (box.href) {
      navigate(box.href);
    } else {
      dispatch(setVisibilityFilter(box));
    }
  };

  const lists = useSelector((state) => (state.listReducer.records as any)['main']);

  return (
    <Grid container spacing={3}>
      {boxes.map((box, index) => (
        <Grid item xs={12} sm={6} md={3} key={index.toString()}>
          <Card
            /* className={`bg-${box.bgcolor}`} */
            sx={{
              p: 2,
              background: theme.palette.primary.main,
              borderRadius: '16px',
              boxShadow: 4,
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 8,
              },
              cursor: 'pointer',
            }}
            onClick={() => boxClicked(box)}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {/* Left Column: Icon + Title */}
              <Typography variant="h6" sx={{ color: theme.palette.common.white, opacity: 0.8 }}>
                <Box display="flex" alignItems="center">
                  {box.title}
                </Box>
              </Typography>

              {/* Right Column: Digits */}
              <Typography variant="h4" sx={{ color: theme.palette.common.white, fontWeight: 'bold' }}>
                {box.digits
                  ? box.digits
                  : lists?.filter(
                    (item: any) => box.value === '0' || String(item[box.key]) === String(box.value),
                  ).length}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListFilter;
