import { Chip, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import {
  useGetAllFieldsQuery,
  useGetAllGeneralsQuery,
  useNewGeneralSetupMutation,
  useUpdateGeneralSetupMutation,
} from 'src/store/api/setups/generalSetupApi';
import {
  MyDatePicker,
  MyStatusSelectField,
  MyTextField,
} from 'src/views/components/fields';
import * as util from 'src/utils/common';
import DynamicCrudTemplate from 'src/views/components/dynamicCrud';
import { DynamicCrudTemplateContext } from 'src/views/components/dynamicCrud/index';
import { ColumnDef } from 'src/store/types';
import * as routes from 'src/constants/routes';

const GeneralSetupInputSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().max(50).required('The title field is required'),
  isActive: Yup.string().required('The status field is required'),
});

export type NewGeneralSetupInput = Yup.InferType<typeof GeneralSetupInputSchema>;

const SetupGeneralPage = () => {
  const { ep, id } = useParams();

  const isAddMode = id === undefined;

  const [submitState, setSubmitState] = useState<{
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any;
  }>({ isLoading: false, isSuccess: false, isError: false, error: {} });


  const [newGeneral, { isLoading, isSuccess, error, isError }] = useNewGeneralSetupMutation();
  const [
    updateGeneral,
    {
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      error: updateError,
      isError: updateIsError,
    },
  ] = useUpdateGeneralSetupMutation();

  const {
    data: generals,
    refetch: refetchGeneral,
    isFetching: generalsIsFetching,
  } = useGetAllGeneralsQuery(ep || '');

  const {
    data: fields,
    refetch: refetchFields,
    isFetching: fieldsIsFetching,
  } = useGetAllFieldsQuery(ep || '');

  // Fetch data on page load or when `ep` changes
  useEffect(() => {
    refetchGeneral();
    refetchFields();
  }, [ep]); // Dependencies: `ep`

  // Update submit state based on mutation results
  useEffect(() => {
    setSubmitState({ isLoading, isSuccess, isError, error });
  }, [isLoading, isSuccess, isError, error]);

  useEffect(() => {
    setSubmitState({
      isLoading: updateIsLoading,
      isSuccess: updateIsSuccess,
      isError: updateIsError,
      error: updateError,
    });
  }, [updateIsLoading, updateIsSuccess, updateIsError, updateError]);

  const submitFormAction = async (values: any) => {
    if (isAddMode) {
      newGeneral({ ep: ep || '', data: values });
    } else {
      updateGeneral({ ep: ep || '', data: values });
    }
  };

  const initialValues = {
    id: 0,
    isActive: '',
    ...fields?.reduce((acc: any, field: any) => {
      acc[field?.toLowerCase()] = field.endsWith('Date') ? null : ''; // Default date as null, others as empty string

      return acc;
    }, {}),
  };

  
  const toCamelCase = (str: string) =>
    str.charAt(0).toLowerCase() + str.slice(1);


  const columns: ColumnDef[] = useMemo(() => {
    if (!fields) return [];

    const dynamicColumns = fields.map((field) => ({
      fieldName: toCamelCase(field),
      title: util.toPascalCaseWithSpaces(field),
      width: '100%',
    }));

    const statusColumn = {
      fieldName: 'isActive',
      title: 'STATUS',
      width: '100%',
      render: ({ row }: { row: any }) => (
        <Chip
          label={row.isActive ? 'Active' : 'Inactive'}
          style={{
            backgroundColor: row.isActive ? '#42A5F5' : '#EF5350', // blue for active, red for inactive
            color: '#fff',
          }}
        />
      ),
    };

    return [...dynamicColumns, statusColumn];
  }, [fields]); // Recalculate when `fields` changes

  return (
    <>
      {fields && (
        <DynamicCrudTemplate
          initialValues={initialValues}
          pageTitle={`Setup - ${util.toPascalCaseWithSpaces(ep || '')}`}
          pageHeaderTitle={`Setup ${util.toPascalCaseWithSpaces(ep || '')}`}
          pageHeaderDescription={`Use this setup to create different types of ${util.toPascalCaseWithSpaces(ep || '')} setups`}
          yupSchema={GeneralSetupInputSchema}
          submitAction={submitFormAction}
          submitState={submitState}

          showEditAction={true}
          newButtonTitle={`New ${util.toPascalCaseWithSpaces(ep || '')} `}
          listAllApi={{ generals, refetchGeneral, generalsIsFetching }}
          listcolumns={columns}
          navigateTo={`/setups/${util.toPascalCase(ep || '')}`}
        >
          <DynamicCrudTemplateContext.Consumer>
            {(formik: any) => (
              <>
                {fields?.map((field, index) => (
                  <Grid item xs={12} key={index}>
                    {field.endsWith('Date') ? (
                      <MyDatePicker
                        formik={formik}
                        name={field.toLowerCase()}
                        label={util.toPascalCase(field)}
                        placeholder=""
                      />
                    ) : (
                      <MyTextField
                        formik={formik}
                        name={field.toLowerCase()}
                        label={formik.values[field.toLowerCase()] ? '' : util.toPascalCase(field)}
                        placeholder=""
                      />
                    )}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <MyStatusSelectField
                    formik={formik}
                    name="isActive"
                    label="Status"
                    placeholder=""
                  />
                </Grid>
              </>
            )}
          </DynamicCrudTemplateContext.Consumer>
        </DynamicCrudTemplate>
      )}
    </>
  );
};

export default SetupGeneralPage;
