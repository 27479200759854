import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import Authenticated from 'src/views/authentication/protected';
import LoginBoxed from 'src/views/authentication/auth/login/boxed';
import SetupGeneralPage from 'src/views/pages/setup/general';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

const Blank = Loadable(lazy(() => import('src/views/dashboard/blank')));
const Client = Loadable(lazy(() => import('src/views/dashboard/Client')));

/* ****Pages***** */
const ModernDash = Loadable(lazy(() => import('src/views/dashboard/Modern')));
const MyTasks = Loadable(lazy(() => import('src/views/pages/apps/calendar/BigCalendar')));
const MyInbox = Loadable(lazy(() => import('src/views/pages/apps/email/Email')));
// My Pages
const SetupControlSourcePage = Loadable(
  lazy(() => import('src/views/pages/setup/controls/source')),
);
const SetupControlCategoryPage = Loadable(
  lazy(() => import('src/views/pages/setup/controls/category')),
);
const SetupControlChecklist = Loadable(
  lazy(() => import('src/views/pages/setup/controls/checklist')),
);
const SetupControlLibraryPage = Loadable(
  lazy(() => import('src/views/pages/setup/controls/library')),
);
const SetupControlObservationPage = Loadable(
  lazy(() => import('src/views/pages/setup/controls/observation')),
);
const SetupWorkprogramTemplatePage = Loadable(
  lazy(() => import('src/views/pages/setup/controls/workprograms')),
);
const ControlCategoriesList = Loadable(
  lazy(() => import('src/views/pages/setup/controls/library/list')),
);
const ObservationAreaList = Loadable(
  lazy(() => import('src/views/pages/setup/controls/observation/list')),
);
const SetupEnagementPage = Loadable(lazy(() => import('src/views/pages/planning/engagement/new')));
const ResourceEngagementList = Loadable(
  lazy(() => import('src/views/pages/planning/engagement/resource/list')),
);
const NewResourceEngagement = Loadable(
  lazy(() => import('src/views/pages/planning/engagement/resource')),
);
const EngagementTimeline = Loadable(
  lazy(() => import('src/views/pages/planning/engagement/Timeline')),
);
const ArchiveEngagementPage = Loadable(
  lazy(() => import('src/views/pages/planning/engagement/Archive')),
);
const ArchiveIssueEngagementPage = Loadable(
  lazy(() => import('src/views/pages/planning/engagement/Archive/Issue')),
);
const ArchiveFinalEngagementPage = Loadable(
  lazy(() => import('src/views/pages/planning/engagement/Archive/Final')),
);
const SetupUsers = Loadable(lazy(() => import('src/views/pages/setup/user/create')));
const SetupRoles = Loadable(lazy(() => import('src/views/pages/setup/user/roles')));
const RolePermissions = Loadable(lazy(() => import('src/views/pages/setup/user/permission')));
const UserMatrix = Loadable(lazy(() => import('src/views/pages/setup/user/matrix')));
const SetupOrganizationUnit = Loadable(
  lazy(() => import('src/views/pages/setup/organization/unit')),
);
const SetupOrganizationUniverse = Loadable(
  lazy(() => import('src/views/pages/setup/organization/universe')),
);
const SetupRiskFactor = Loadable(lazy(() => import('src/views/pages/setup/risk/Factor')));
const RiskFactorCriteria = Loadable(lazy(() => import('src/views/pages/setup/risk/Criteria')));
const RiskFactorCriteriaMatrix = Loadable(
  lazy(() => import('src/views/pages/setup/risk/Criteria/List')),
);
const RiskAssessmentList = Loadable(
  lazy(() => import('src/views/pages/setup/risk/Assessment/List')),
);
const RiskFactorAssessment = Loadable(lazy(() => import('src/views/pages/setup/risk/Assessment')));
const RiskAssessmentPlan = Loadable(
  lazy(() => import('src/views/pages/setup/risk/Assessment/Plan')),
);
const RiskAnalysisMatrixView = Loadable(lazy(() => import('src/views/reports/RiskAnalysisMatrix')));
const ObservationSummaryView = Loadable(lazy(() => import('src/views/reports/ObservationSummary')));
const PostObservationSummaryView = Loadable(
  lazy(() => import('src/views/reports/PostObservationSummary')),
);
const ObservationStatusSummaryView = Loadable(
  lazy(() => import('src/views/reports/ObservationStatusSummary')),
);
const BranchPerformanceView = Loadable(
  lazy(() => import('src/views/reports/BranchAuditorPerformance')),
);
const AuditDaysPlanningView = Loadable(lazy(() => import('src/views/reports/AuditDays')));
const EngagementSummaryView = Loadable(lazy(() => import('src/views/reports/EngagmentSummary')));
const AuditProgressView = Loadable(
  lazy(() => import('src/views/reports/EngagmentSummary/AuditProgress')),
);
const ComplianceSummaryView = Loadable(lazy(() => import('src/views/reports/ComplianceSummary')));
const AuditPeriodSummary = Loadable(lazy(() => import('src/views/reports/AuditPeriodSummary')));
const WorkProgramEngagementListPage = Loadable(
  lazy(() => import('src/views/pages/execution/workprogram/List')),
);
const WorkProgramCreate = Loadable(
  lazy(() => import('src/views/pages/execution/workprogram/Create')),
);
const WorkProgramReview = Loadable(
  lazy(() => import('src/views/pages/execution/workprogram/Review')),
);
const WorkProgramExecute = Loadable(
  lazy(() => import('src/views/pages/execution/workprogram/Execute')),
);
const WorkProgramActivity = Loadable(
  lazy(() => import('src/views/pages/execution/workprogram/Activity')),
);
const WorkProgramActivityList = Loadable(
  lazy(() => import('src/views/pages/execution/workprogram/ActivityList')),
);
const ObservationDrafting = Loadable(
  lazy(() => import('src/views/pages/execution/observation/Drafting/Control/Create')),
);
const ObservationDraftingList = Loadable(
  lazy(() => import('src/views/pages/execution/observation/Drafting/Control/List')),
);
const ObservationDraftingChecklist = Loadable(
  lazy(() => import('src/views/pages/execution/observation/Drafting/Checklist/List')),
);
const ObservationSampling = Loadable(
  lazy(() => import('src/views/pages/execution/observation/Sampling')),
);
const ReviewManagementComments = Loadable(
  lazy(() => import('src/views/pages/review/Engagement/Comments')),
);
const NewManagementComments = Loadable(
  lazy(() => import('src/views/pages/review/Engagement/Comments/New')),
);
const FinalizeEngagement = Loadable(
  lazy(() => import('src/views/pages/review/Engagement/Finalize')),
);
const ReviewObservation = Loadable(lazy(() => import('src/views/pages/review/Observation/List')));
const RemindersPage = Loadable(lazy(() => import('src/views/pages/review/Reminders')));
const ComplianceEngagement = Loadable(lazy(() => import('src/views/pages/compliance/Engagement')));
const ComplianceEngagementPostReview = Loadable(
  lazy(() => import('src/views/pages/compliance/PostReview')),
);
const ComplianceObservation = Loadable(
  lazy(() => import('src/views/pages/compliance/Observation/List')),
);
const ComplianceObservationComments = Loadable(
  lazy(() => import('src/views/pages/review/Observation/Comments')),
);
const DraftEngagement = Loadable(lazy(() => import('src/views/pages/review/Draft/Report')));
const DraftReportGenerate = Loadable(
  lazy(() => import('src/views/pages/review/Draft/Report/Generate')),
);
const DraftReportGenerateFx = Loadable(
  lazy(() => import('src/views/pages/review/Draft/Report/Generate/Checklist')),
);
const Status404 = Loadable(lazy(() => import('src/views/pages/status/Status404')));
const Status500 = Loadable(lazy(() => import('src/views/pages/status/Status500')));
const StatusForbidden = Loadable(lazy(() => import('src/views/pages/status/Status403')));
const StatusMaintenance = Loadable(lazy(() => import('src/views/pages/status/Maintenance')));
const StatusNotifications = Loadable(lazy(() => import('src/views/pages/status/Notification')));

const Landingpage = Loadable(lazy(() => import('src/views/pages/landingpage/Landingpage')));

const AuditReviewRating = Loadable(lazy(() => import('src/views/pages/review/Rating/View')));

const AuthenticatedFullLayout = () => {
  return (
    <>
      <Authenticated>
        <FullLayout />
      </Authenticated>
    </>
  );
};

const Router = [
  {
    path: '/',
    element: <AuthenticatedFullLayout />,
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      {
        path: '/dashboards/blank',
        element: <Blank />,
      },
      {
        path: '/dashboards/client',
        element: <Client />,
      },
      { path: '/dashboards/index', exact: true, element: <ModernDash /> },
      { path: '/my/tasks', exact: true, element: <MyTasks /> },
      { path: '/my/inbox', exact: true, element: <MyInbox /> },
      // { path: '/dashboards/ecommerce', exact: true, element: <EcommerceDash /> },
      {
        path: 'setup',
        children: [
          {
            path: 'control',
            children: [
              { path: 'source', element: <SetupControlSourcePage /> },
              { path: 'category', element: <SetupControlCategoryPage /> },
              { path: 'template', element: <SetupWorkprogramTemplatePage /> },
            ],
          },
        ],
      },
      {
        path: 'setup',
        children: [
          {
            path: 'users',
            children: [
              { path: '', element: <SetupUsers /> },
              { path: ':id', element: <SetupUsers /> },
            ],
          },
          {
            path: 'roles',
            children: [
              { path: '', element: <SetupRoles /> },
              { path: ':id', element: <SetupRoles /> },
            ],

          },
          {
            path: 'perms',
            element: <RolePermissions />,
          },
          {
            path: 'matrix',
            element: <UserMatrix />,
          },
          {
            path: 'organunit',
            // element: <SetupOrganizationUnit />
            children: [
              { path: '', element: <SetupOrganizationUnit /> },
              { path: ':id', element: <SetupOrganizationUnit /> },
            ]
          },
          {
            path: 'universe',
            //  element: <SetupOrganizationUniverse />
            children: [
              { path: '', element: <SetupOrganizationUniverse /> },
              { path: ':id', element: <SetupOrganizationUniverse /> },
            ]
          },
          {
            path: 'control',
            children: [
              {
                path: 'source',
                // element: <SetupControlSourcePage />
                children: [
                  { path: '', element: <SetupControlSourcePage /> },
                  { path: ':id', element: <SetupControlSourcePage /> },
                ]
              },
              {
                path: 'category',
                //  element: <SetupControlCategoryPage />,
                children: [
                  { path: '', element: <SetupControlCategoryPage /> },
                  { path: ':id', element: <SetupControlCategoryPage /> },

                ]
              },
              {
                path: 'checklist',
                children: [
                  { path: '', element: <SetupControlChecklist /> },
                  { path: ':id', element: <SetupControlChecklist /> }
                ]
              },
              {
                path: 'observation',
                children: [
                  { path: '', element: <ObservationAreaList /> },
                  { path: 'list/:cat', element: <SetupControlObservationPage /> },
                  { path: ':id', element: <SetupControlObservationPage /> },
                ],
              },
            ],
          },
          {
            path: 'controls',
            children: [
              { path: '', element: <ControlCategoriesList /> },
              { path: 'list/:cat', element: <SetupControlLibraryPage /> },
              { path: ':id', element: <SetupControlLibraryPage /> },
            ],
          },
          {
            path: 'risk',
            children: [
              {
                path: 'factor',
                // element: <SetupRiskFactor />,  
                children: [
                  { path: '', element: <SetupRiskFactor /> },
                  { path: ':id', element: <SetupRiskFactor /> },
                  // { path: 'new', element: <RiskFactorList /> }, // New route here

                  // { path: '', element: <SetupRiskFactor /> },
                ],
              },
              {
                path: 'criteria',
                children: [
                  { path: ':id', element: <RiskFactorCriteria /> },
                  { path: 'view', element: <RiskFactorCriteriaMatrix /> },
                ],
              },
              {
                path: 'assessment',
                children: [
                  { path: 'list', element: <RiskAssessmentList /> },
                  { path: 'plan', element: <RiskAssessmentPlan /> },
                  { path: '', element: <RiskFactorAssessment /> },
                  { path: ':id', element: <RiskFactorAssessment /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'planning',
        children: [
          {
            path: 'engagement',
            children: [
              { path: 'list', element: <ResourceEngagementList /> },
              { path: '', element: <SetupEnagementPage /> },
              { path: ':id', element: <SetupEnagementPage /> },
              { path: 'timeline', element: <EngagementTimeline /> },
              { path: 'archive', element: <ArchiveEngagementPage /> },
              { path: 'issue', element: <ArchiveIssueEngagementPage /> },
              { path: 'final', element: <ArchiveFinalEngagementPage /> },
            ],
          },
          {
            path: 'resource',
            children: [{ path: 'assign', element: <NewResourceEngagement /> }],
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: 'obvsumm',
            children: [
              { path: '', element: <ObservationSummaryView /> },
              { path: ':id', element: <ObservationSummaryView /> },
            ],
          },
          {
            path: 'postobvsumm',
            element: <PostObservationSummaryView />,
          },
          {
            path: 'obvstatsumm',
            element: <ObservationStatusSummaryView />,
          },
          {
            path: 'riskmatrix',
            element: <RiskAnalysisMatrixView />,
          },
          {
            path: 'auditperm',
            element: <BranchPerformanceView />,
          },
          {
            path: 'auditdays',
            element: <AuditDaysPlanningView />,
          },
          {
            path: 'engsumm',
            children: [
              { path: '', element: <EngagementSummaryView /> },
              { path: ':id', element: <EngagementSummaryView /> },
            ],
          },
          {
            path: 'auditpro',
            children: [{ path: ':id', element: <AuditProgressView /> }],
          },
          {
            path: 'auditperiod',
            children: [{ path: '', element: <AuditPeriodSummary /> }],
          },
          {
            path: 'compsumm',
            children: [
              { path: '', element: <ComplianceSummaryView /> },
              { path: ':id', element: <ComplianceSummaryView /> },
            ],
          },
        ],
      },
      {
        path: 'workprogram',
        children: [
          {
            path: 'engagement',
            children: [
              {
                path: 'manage',
                element: <WorkProgramEngagementListPage mode="manage" />,
              },
              {
                path: 'view',
                element: <WorkProgramEngagementListPage mode="view" />,
              },
            ],
          },
          {
            path: 'controls',
            children: [
              { path: 'create', element: <WorkProgramCreate /> },
              { path: 'review', element: <WorkProgramReview /> },
              { path: 'execute', element: <WorkProgramExecute /> },
            ],
          },
          {
            path: 'activity',
            children: [
              { path: '', element: <WorkProgramActivity /> },
              { path: ':id', element: <WorkProgramActivity /> },
            ],
          },
          {
            path: 'activityList',
            element: <WorkProgramActivityList />,
          },
          {
            path: 'observation',
            children: [
              {
                path: 'draft',
                children: [
                  { path: '', element: <ObservationDrafting /> },
                  { path: ':id', element: <ObservationDrafting /> },
                ],
              },
              {
                path: 'list',
                element: <ObservationDraftingList />,
              },
              {
                path: 'checklist',
                element: <ObservationDraftingChecklist />,
              },
              {
                path: 'sampling',
                children: [
                  { path: '', element: <ObservationSampling /> },
                  { path: ':id', element: <ObservationSampling /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'review',
        children: [
          {
            path: 'management',
            children: [{ path: 'comments', element: <ReviewManagementComments /> }],
          },
          {
            path: 'post',
            children: [{ path: 'comments', element: <NewManagementComments /> }],
          },
          {
            path: 'engagement',
            children: [{ path: 'finalize', element: <FinalizeEngagement /> }],
          },
          {
            path: 'observation',
            children: [{ path: 'list', element: <ReviewObservation /> }],
          },
          {
            path: 'reminders',
            element: <RemindersPage />,
          },
          {
            path: 'draft',
            children: [
              { path: 'report', element: <DraftEngagement /> },
              { path: 'rating', element: <AuditReviewRating /> },
              { path: 'generate', element: <DraftReportGenerate /> },
              { path: 'generatefx', element: <DraftReportGenerateFx /> },
            ],
          },
        ],
      },
      {
        path: 'compliance',
        children: [
          {
            path: 'engagement',
            element: <ComplianceEngagement />,
          },
          {
            path: 'postreview',
            element: <ComplianceEngagementPostReview />,
          },
          {
            path: 'observation',
            children: [
              {
                path: 'list',
                element: <ComplianceObservation />,
              },
              { path: 'comments', element: <ComplianceObservationComments /> },
            ],
          },
          {
            path: 'draft',
            children: [{ path: 'report', element: <DraftEngagement /> }],
          },
        ],
      },
      {
        path: 'setups',
        // element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <SetupGeneralPage />,
          },
          {
            path: ':ep',
            element: <SetupGeneralPage />,
          },
          {
            path: ':ep/:id',
            element: <SetupGeneralPage />,
          },
          // {
          //   path: 'auditqtr',
          //   children: [
          //     { path: '', element: <SetupAuditQuarter /> },
          //     { path: ':id', element: <SetupAuditQuarter /> },
          //   ],
          // },
        ],
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: '403',
            // element: <FullLayout />,
            // children: [{ path: '', element: <StatusForbidden /> }],
            element: <StatusForbidden />,
          },
          {
            path: 'notifications',
            element: <BlankLayout />,
            children: [{ path: '', element: <StatusNotifications /> }],
          },
        ],
      },
      // { path: '*', element: <Navigate to="/status/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      // { path: '/auth/404', element: <Status404 /> },
      { path: '/auth/login', element: <Landingpage /> },
      // { path: '/landingpage', element: <Landingpage /> },
      // { path: '/auth/login2', element: <Login2 /> },
      // { path: '/auth/register', element: <Register /> },
      // { path: '/auth/register2', element: <Register2 /> },
      // { path: '/auth/forgot-password', element: <ForgotPassword /> },
      // { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      // { path: '/auth/two-steps', element: <TwoSteps /> },
      // { path: '/auth/two-steps2', element: <TwoSteps2 /> },
      // { path: '/auth/maintenance', element: <Maintenance /> },
      // { path: '/landingpage', element: <Landingpage /> },
      // { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '*', element: <Status404 /> },
    ],
  },
];

export default Router;
